interface Scripts {
  name: string;
  src: string;
  modifiers: string[] | null;
}
export class ScriptLoader {
  name: string;
  modifiers: {name, value}[] | null;
  constructor(name, modifiers = null) {
    this.name = name;
    this.modifiers = modifiers;
  }
}
export const ScriptStore: Scripts[] = [
  {name: 'ymaps3', src: 'https://api-maps.yandex.ru/v3/?apikey=API_KEY&lang=ru_RU', modifiers: ['API_KEY']},
];
