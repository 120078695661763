import {Injectable} from "@angular/core";
import {ScriptLoader, ScriptStore} from "../stores/script.store";

declare var document: any;

// https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        modifiers: script.modifiers
      };
    });
  }

  load(...scripts: ScriptLoader[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(loader: ScriptLoader) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[loader.name].loaded) {
        resolve({script: loader.name, loaded: true, status: 'Already Loaded'});
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[loader.name].src;
        if(this.scripts[loader.name].modifiers && loader.modifiers) {
          for(let modifier of loader.modifiers) {
            script.src = script.src.replace(modifier.name, modifier.value);
          }
        }
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[loader.name].loaded = true;
              resolve({script: loader.name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[loader.name].loaded = true;
            resolve({script: loader.name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: loader.name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
